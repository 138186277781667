import React, { useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import HomeScreen from "./HomeScreen";
import { Navigate } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import FirstPage from "./FirstPage";
/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();   
    // useEffect(()=>{
    //     isAuthenticated 
    //     ? navigateToHomeScreen()
    //     : navigateToLogIn()

    // },[isAuthenticated])

    return (
        <div>
           
            {isAuthenticated ? <Navigate  to="CIHome" />
                :
                // <LoginPage/>
                <FirstPage/>
                
                }

            {/* <h5><center>Welcome to the Microsoft Authentication Library For Javascript - React Quickstart</center></h5> */}
            <br />
            <br />
            {props.children}
        </div>
    );
};
