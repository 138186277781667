import React from 'react'
import { Route, Router, Switch, Redirect, Routes } from 'react-router-dom';
import App from '../App';
import HomeScreen from '../components/HomeScreen';
import { LoginPage } from '../components/LoginPage';
import { AuthProvider } from '../utils/Auth';
import "../styles/App.css"
import Footer from '../Layouts/footer';
import { useIsAuthenticated } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import ProtectedRoutes from './ProtectedRoutes';
import FirstPage from '../components/FirstPage';

export const Routess = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <AuthProvider>
                <div className="main-body">
                    <div className="main-container">
                        <Routes>
                            {/* <Route element={<ProtectedRoutes />}>
                                <Route path='/home' element={<HomeScreen />} />
                            </Route>
                            <Route exact path='/' element={<App/>} /> */}
                            <Route path='/' element={<App />} />
                            <Route path='CIHome' element={<HomeScreen />} />

                        </Routes>
                    </div>
                </div>
                <footer>
                    <Footer />
                </footer>
            </AuthProvider>
        </>
    )
}
